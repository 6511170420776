ion-title {
  // Adding !important to force precedence in SSR
  font-weight: 400 !important;
  letter-spacing: 0.4px !important;
}

// Override Ionic styles
.input-clear-icon {
  // Change these properties so the Ionic close icon aligns with show/hide password
  &.sc-ion-input-ios,
  &.sc-ion-input-md {
    width: calc(1.2rem);
    margin-inline-start: 0.5rem;
  }
}

ion-toolbar {
  --background: var(--toolbar-background);
  --color: var(--toolbar-color);
  ion-buttons {
    ion-button {
      --background: var(--toolbar-background);
      --color: var(--toolbar-color);
      ion-icon {
        --color: var(--toolbar-color);
      }
    }
  }
}

ion-title {
  --color: var(--toolbar-title-color);
}

ion-tab-bar {
  --background: var(--tab-bar-background);
}

ion-tab-button {
  --color: var(--tab-button-color);
  --color-selected: var(--tab-button-color-selected);
}
ion-content {
  --background: var(--app-background);
}

ion-button {
  --color: var(--button-color);
}
h3 {
  color: var(--h3-color);
}

ion-toggle {
  --background: var(--ion-color-secondary);
  --background-checked: var(--ion-color-success);
}

ion-checkbox {
  --background-checked: var(--ion-color-success);
}
